<mat-slide-toggle
  [labelPosition]="getLabelPosition()"
  [required]="config().getRequired()"
  [color]="config().getColor()"
  [disabled]="config().getDisabled()"
  [disableRipple]="config().getDisableRipple()"
  [tabIndex]="config().getTabIndex()"
  [checked]="config().getChecked()"
  [hideIcon]="config().getHideIcon()"
  (change)="onSlideToggleChangeEvent($event)"
>
  <ng-content></ng-content>
</mat-slide-toggle>
