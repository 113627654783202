<table
  mat-table
  [dataSource]="config().getDataSourceList()"
  class="mat-elevation-z8"
>
  @for(colummn of config().getDisplayedColumnsList(); track index; let index =
  $index) {
  <ng-container
    matColumnDef="{{ config().getDisplayedColumnsList()[index] }}"
    [sticky]="isStickyColumn(index)"
  >
    <th mat-header-cell *matHeaderCellDef>
      {{ config().getDisplayedColumnsList()[index] }}
    </th>
    <td
      mat-cell
      (click)="onClickCell(element, index)"
      *matCellDef="let element"
    >
      {{ element.getCellValuesList()[index] }}
    </td>
  </ng-container>
  }

  <tr
    mat-header-row
    *matHeaderRowDef="config().getDisplayedColumnsList(); sticky: isStickyHeader()"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: config().getDisplayedColumnsList();"
  ></tr>
</table>
