<mat-slider
  [disabled]="config().getDisabled()"
  [discrete]="config().getDiscrete()"
  [showTickMarks]="config().getShowTickMarks()"
  [min]="config().getMin()"
  [color]="getColor()"
  [disableRipple]="config().getDisableRipple()"
  [max]="config().getMax()"
  [step]="config().getStep()"
  [style]="getStyle()"
>
  <input
    matSliderThumb
    (valueChange)="onValueChange($event)"
    [(ngModel)]="value"
  />
</mat-slider>
