// source: mesop/components/autocomplete/autocomplete.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.components.autocomplete.AutocompleteOption', null, global);
goog.exportSymbol('proto.mesop.components.autocomplete.AutocompleteOptionGroup', null, global);
goog.exportSymbol('proto.mesop.components.autocomplete.AutocompleteOptionSet', null, global);
goog.exportSymbol('proto.mesop.components.autocomplete.AutocompleteOptionSet.OptionTypeCase', null, global);
goog.exportSymbol('proto.mesop.components.autocomplete.AutocompleteType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.autocomplete.AutocompleteOptionGroup.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.autocomplete.AutocompleteOptionGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.autocomplete.AutocompleteOptionGroup.displayName = 'proto.mesop.components.autocomplete.AutocompleteOptionGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.autocomplete.AutocompleteOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.autocomplete.AutocompleteOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.autocomplete.AutocompleteOption.displayName = 'proto.mesop.components.autocomplete.AutocompleteOption';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mesop.components.autocomplete.AutocompleteOptionSet.oneofGroups_);
};
goog.inherits(proto.mesop.components.autocomplete.AutocompleteOptionSet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.autocomplete.AutocompleteOptionSet.displayName = 'proto.mesop.components.autocomplete.AutocompleteOptionSet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.autocomplete.AutocompleteType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.autocomplete.AutocompleteType.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.autocomplete.AutocompleteType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.autocomplete.AutocompleteType.displayName = 'proto.mesop.components.autocomplete.AutocompleteType';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.autocomplete.AutocompleteOptionGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.mesop.components.autocomplete.AutocompleteOption.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionGroup}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.autocomplete.AutocompleteOptionGroup;
  return proto.mesop.components.autocomplete.AutocompleteOptionGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionGroup}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = new proto.mesop.components.autocomplete.AutocompleteOption;
      reader.readMessage(value,proto.mesop.components.autocomplete.AutocompleteOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.autocomplete.AutocompleteOptionGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mesop.components.autocomplete.AutocompleteOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated AutocompleteOption options = 2;
 * @return {!Array<!proto.mesop.components.autocomplete.AutocompleteOption>}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.mesop.components.autocomplete.AutocompleteOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.components.autocomplete.AutocompleteOption, 2));
};


/**
 * @param {!Array<!proto.mesop.components.autocomplete.AutocompleteOption>} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} returns this
*/
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mesop.components.autocomplete.AutocompleteOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption}
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mesop.components.autocomplete.AutocompleteOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionGroup} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOptionGroup.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.autocomplete.AutocompleteOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.autocomplete.AutocompleteOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    value: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption}
 */
proto.mesop.components.autocomplete.AutocompleteOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.autocomplete.AutocompleteOption;
  return proto.mesop.components.autocomplete.AutocompleteOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.autocomplete.AutocompleteOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption}
 */
proto.mesop.components.autocomplete.AutocompleteOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.autocomplete.AutocompleteOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.autocomplete.AutocompleteOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOption} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteOption.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.OptionTypeCase = {
  OPTION_TYPE_NOT_SET: 0,
  OPTION_GROUP: 1,
  OPTION: 2
};

/**
 * @return {proto.mesop.components.autocomplete.AutocompleteOptionSet.OptionTypeCase}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.getOptionTypeCase = function() {
  return /** @type {proto.mesop.components.autocomplete.AutocompleteOptionSet.OptionTypeCase} */(jspb.Message.computeOneofCase(this, proto.mesop.components.autocomplete.AutocompleteOptionSet.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.autocomplete.AutocompleteOptionSet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionSet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.toObject = function(includeInstance, msg) {
  var f, obj = {
    optionGroup: (f = msg.getOptionGroup()) && proto.mesop.components.autocomplete.AutocompleteOptionGroup.toObject(includeInstance, f),
    option: (f = msg.getOption()) && proto.mesop.components.autocomplete.AutocompleteOption.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.autocomplete.AutocompleteOptionSet;
  return proto.mesop.components.autocomplete.AutocompleteOptionSet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionSet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mesop.components.autocomplete.AutocompleteOptionGroup;
      reader.readMessage(value,proto.mesop.components.autocomplete.AutocompleteOptionGroup.deserializeBinaryFromReader);
      msg.setOptionGroup(value);
      break;
    case 2:
      var value = new proto.mesop.components.autocomplete.AutocompleteOption;
      reader.readMessage(value,proto.mesop.components.autocomplete.AutocompleteOption.deserializeBinaryFromReader);
      msg.setOption(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.autocomplete.AutocompleteOptionSet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionSet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOptionGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mesop.components.autocomplete.AutocompleteOptionGroup.serializeBinaryToWriter
    );
  }
  f = message.getOption();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mesop.components.autocomplete.AutocompleteOption.serializeBinaryToWriter
    );
  }
};


/**
 * optional AutocompleteOptionGroup option_group = 1;
 * @return {?proto.mesop.components.autocomplete.AutocompleteOptionGroup}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.getOptionGroup = function() {
  return /** @type{?proto.mesop.components.autocomplete.AutocompleteOptionGroup} */ (
    jspb.Message.getWrapperField(this, proto.mesop.components.autocomplete.AutocompleteOptionGroup, 1));
};


/**
 * @param {?proto.mesop.components.autocomplete.AutocompleteOptionGroup|undefined} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet} returns this
*/
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.setOptionGroup = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.mesop.components.autocomplete.AutocompleteOptionSet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.clearOptionGroup = function() {
  return this.setOptionGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.hasOptionGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AutocompleteOption option = 2;
 * @return {?proto.mesop.components.autocomplete.AutocompleteOption}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.getOption = function() {
  return /** @type{?proto.mesop.components.autocomplete.AutocompleteOption} */ (
    jspb.Message.getWrapperField(this, proto.mesop.components.autocomplete.AutocompleteOption, 2));
};


/**
 * @param {?proto.mesop.components.autocomplete.AutocompleteOption|undefined} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet} returns this
*/
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.setOption = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mesop.components.autocomplete.AutocompleteOptionSet.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet} returns this
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.clearOption = function() {
  return this.setOption(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteOptionSet.prototype.hasOption = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.autocomplete.AutocompleteType.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.autocomplete.AutocompleteType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.autocomplete.AutocompleteType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteType.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    id: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    placeholder: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    value: (f = jspb.Message.getField(msg, 4)) == null ? undefined : f,
    readonly: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
    hideRequiredMarker: (f = jspb.Message.getBooleanField(msg, 6)) == null ? undefined : f,
    color: (f = jspb.Message.getField(msg, 7)) == null ? undefined : f,
    floatLabel: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    appearance: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    subscriptSizing: (f = jspb.Message.getField(msg, 10)) == null ? undefined : f,
    hintLabel: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    label: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    onInputHandlerId: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    onEnterHandlerId: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    onSelectionChangeHandlerId: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.mesop.components.autocomplete.AutocompleteOptionSet.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType}
 */
proto.mesop.components.autocomplete.AutocompleteType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.autocomplete.AutocompleteType;
  return proto.mesop.components.autocomplete.AutocompleteType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.autocomplete.AutocompleteType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType}
 */
proto.mesop.components.autocomplete.AutocompleteType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholder(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideRequiredMarker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloatLabel(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppearance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptSizing(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHintLabel(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnInputHandlerId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnEnterHandlerId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnSelectionChangeHandlerId(value);
      break;
    case 16:
      var value = new proto.mesop.components.autocomplete.AutocompleteOptionSet;
      reader.readMessage(value,proto.mesop.components.autocomplete.AutocompleteOptionSet.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.autocomplete.AutocompleteType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.autocomplete.AutocompleteType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.autocomplete.AutocompleteType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeBool(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeString(
      7,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.mesop.components.autocomplete.AutocompleteOptionSet.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool disabled = 1;
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setDisabled = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearDisabled = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasDisabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string placeholder = 3;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getPlaceholder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setPlaceholder = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearPlaceholder = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasPlaceholder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string value = 4;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearValue = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool readonly = 5;
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setReadonly = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearReadonly = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasReadonly = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool hide_required_marker = 6;
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getHideRequiredMarker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setHideRequiredMarker = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearHideRequiredMarker = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasHideRequiredMarker = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string color = 7;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 7, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearColor = function() {
  return jspb.Message.setField(this, 7, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasColor = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string float_label = 8;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getFloatLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setFloatLabel = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearFloatLabel = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasFloatLabel = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string appearance = 9;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getAppearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setAppearance = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearAppearance = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasAppearance = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string subscript_sizing = 10;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getSubscriptSizing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setSubscriptSizing = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearSubscriptSizing = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasSubscriptSizing = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string hint_label = 11;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getHintLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setHintLabel = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearHintLabel = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasHintLabel = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string label = 12;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string on_input_handler_id = 13;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getOnInputHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setOnInputHandlerId = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearOnInputHandlerId = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasOnInputHandlerId = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string on_enter_handler_id = 14;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getOnEnterHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setOnEnterHandlerId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearOnEnterHandlerId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasOnEnterHandlerId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string on_selection_change_handler_id = 15;
 * @return {string}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getOnSelectionChangeHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.setOnSelectionChangeHandlerId = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearOnSelectionChangeHandlerId = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.hasOnSelectionChangeHandlerId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated AutocompleteOptionSet options = 16;
 * @return {!Array<!proto.mesop.components.autocomplete.AutocompleteOptionSet>}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.mesop.components.autocomplete.AutocompleteOptionSet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.components.autocomplete.AutocompleteOptionSet, 16));
};


/**
 * @param {!Array<!proto.mesop.components.autocomplete.AutocompleteOptionSet>} value
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
*/
proto.mesop.components.autocomplete.AutocompleteType.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.mesop.components.autocomplete.AutocompleteOptionSet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.autocomplete.AutocompleteOptionSet}
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.mesop.components.autocomplete.AutocompleteOptionSet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.autocomplete.AutocompleteType} returns this
 */
proto.mesop.components.autocomplete.AutocompleteType.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


goog.object.extend(exports, proto.mesop.components.autocomplete);
