<mat-radio-group
  [style]="getStyle()"
  [color]="getColor()"
  [labelPosition]="getLabelPosition()"
  [value]="config().getValue()"
  [disabled]="config().getDisabled()"
  (change)="onRadioChangeEvent($event)"
>
  @for(option of config().getOptionsList(); track index; let index = $index) {
  <mat-radio-button
    [aria-label]="config().getOptionsList()[index].getLabel()!"
    [value]="config().getOptionsList()[index].getValue()"
  >
    {{config().getOptionsList()[index].getLabel()}}
  </mat-radio-button>
  }
</mat-radio-group>
