<mat-checkbox
  [style]="getStyle()"
  [labelPosition]="getLabelPosition()"
  [disableRipple]="config().getDisableRipple()"
  [tabIndex]="config().getTabIndex()"
  [color]="config().getColor()"
  [checked]="config().getChecked()"
  [disabled]="config().getDisabled()"
  [indeterminate]="config().getIndeterminate()"
  (change)="onCheckboxChangeEvent($event)"
  (indeterminateChange)="onCheckboxIndeterminateChangeEvent($event)"
>
  <ng-content></ng-content>
</mat-checkbox>
