<div
  matBadge
  [matBadgeColor]="getColor()"
  [matBadgeOverlap]="config().getOverlap()"
  [matBadgeDisabled]="config().getDisabled()"
  [matBadgePosition]="getPosition()"
  [matBadge]="config().getContent()"
  [matBadgeDescription]="config().getDescription()!"
  [matBadgeSize]="getSize()"
  [matBadgeHidden]="config().getHidden()"
>
  <ng-content></ng-content>
</div>
