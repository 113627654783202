<mat-tooltip
  [matTooltipPosition]="getPosition()"
  [matTooltipPositionAtOrigin]="config().getPositionAtOrigin()"
  [matTooltipDisabled]="config().getDisabled()"
  [matTooltipShowDelay]="config().getShowDelayMs()"
  [matTooltipHideDelay]="config().getHideDelayMs()"
  [matTooltip]="config().getMessage()!"
>
  <ng-content></ng-content>
</mat-tooltip>
