// source: mesop/components/select/select.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.components.select.SelectChangeEvent', null, global);
goog.exportSymbol('proto.mesop.components.select.SelectOption', null, global);
goog.exportSymbol('proto.mesop.components.select.SelectType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.select.SelectChangeEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.select.SelectChangeEvent.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.select.SelectChangeEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.select.SelectChangeEvent.displayName = 'proto.mesop.components.select.SelectChangeEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.select.SelectType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.select.SelectType.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.select.SelectType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.select.SelectType.displayName = 'proto.mesop.components.select.SelectType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.select.SelectOption = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.select.SelectOption, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.select.SelectOption.displayName = 'proto.mesop.components.select.SelectOption';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.select.SelectChangeEvent.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.select.SelectChangeEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.select.SelectChangeEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.select.SelectChangeEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.select.SelectChangeEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.select.SelectChangeEvent}
 */
proto.mesop.components.select.SelectChangeEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.select.SelectChangeEvent;
  return proto.mesop.components.select.SelectChangeEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.select.SelectChangeEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.select.SelectChangeEvent}
 */
proto.mesop.components.select.SelectChangeEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.select.SelectChangeEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.select.SelectChangeEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.select.SelectChangeEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.select.SelectChangeEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string values = 1;
 * @return {!Array<string>}
 */
proto.mesop.components.select.SelectChangeEvent.prototype.getValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.components.select.SelectChangeEvent} returns this
 */
proto.mesop.components.select.SelectChangeEvent.prototype.setValuesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.select.SelectChangeEvent} returns this
 */
proto.mesop.components.select.SelectChangeEvent.prototype.addValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.select.SelectChangeEvent} returns this
 */
proto.mesop.components.select.SelectChangeEvent.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.select.SelectType.repeatedFields_ = [16];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.select.SelectType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.select.SelectType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.select.SelectType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.select.SelectType.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: (f = jspb.Message.getBooleanField(msg, 2)) == null ? undefined : f,
    disableRipple: (f = jspb.Message.getBooleanField(msg, 3)) == null ? undefined : f,
    multiple: (f = jspb.Message.getBooleanField(msg, 18)) == null ? undefined : f,
    tabIndex: (f = jspb.Message.getOptionalFloatingPointField(msg, 4)) == null ? undefined : f,
    placeholder: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    value: (f = jspb.Message.getField(msg, 9)) == null ? undefined : f,
    onSelectOpenedChangeEventHandlerId: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    onSelectSelectionChangeEventHandlerId: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    optionsList: jspb.Message.toObjectList(msg.getOptionsList(),
    proto.mesop.components.select.SelectOption.toObject, includeInstance),
    label: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.select.SelectType}
 */
proto.mesop.components.select.SelectType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.select.SelectType;
  return proto.mesop.components.select.SelectType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.select.SelectType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.select.SelectType}
 */
proto.mesop.components.select.SelectType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisableRipple(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMultiple(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setTabIndex(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholder(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnSelectOpenedChangeEventHandlerId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnSelectSelectionChangeEventHandlerId(value);
      break;
    case 16:
      var value = new proto.mesop.components.select.SelectOption;
      reader.readMessage(value,proto.mesop.components.select.SelectOption.deserializeBinaryFromReader);
      msg.addOptions(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.select.SelectType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.select.SelectType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.select.SelectType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.select.SelectType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeBool(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeBool(
      18,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeString(
      9,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getOptionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      16,
      f,
      proto.mesop.components.select.SelectOption.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional bool disabled = 2;
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setDisabled = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearDisabled = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasDisabled = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool disable_ripple = 3;
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.getDisableRipple = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setDisableRipple = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearDisableRipple = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasDisableRipple = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool multiple = 18;
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.getMultiple = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setMultiple = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearMultiple = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasMultiple = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional double tab_index = 4;
 * @return {number}
 */
proto.mesop.components.select.SelectType.prototype.getTabIndex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setTabIndex = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearTabIndex = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasTabIndex = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string placeholder = 6;
 * @return {string}
 */
proto.mesop.components.select.SelectType.prototype.getPlaceholder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setPlaceholder = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearPlaceholder = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasPlaceholder = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string value = 9;
 * @return {string}
 */
proto.mesop.components.select.SelectType.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearValue = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasValue = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string on_select_opened_change_event_handler_id = 14;
 * @return {string}
 */
proto.mesop.components.select.SelectType.prototype.getOnSelectOpenedChangeEventHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setOnSelectOpenedChangeEventHandlerId = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearOnSelectOpenedChangeEventHandlerId = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasOnSelectOpenedChangeEventHandlerId = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string on_select_selection_change_event_handler_id = 15;
 * @return {string}
 */
proto.mesop.components.select.SelectType.prototype.getOnSelectSelectionChangeEventHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setOnSelectSelectionChangeEventHandlerId = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearOnSelectSelectionChangeEventHandlerId = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasOnSelectSelectionChangeEventHandlerId = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * repeated SelectOption options = 16;
 * @return {!Array<!proto.mesop.components.select.SelectOption>}
 */
proto.mesop.components.select.SelectType.prototype.getOptionsList = function() {
  return /** @type{!Array<!proto.mesop.components.select.SelectOption>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.components.select.SelectOption, 16));
};


/**
 * @param {!Array<!proto.mesop.components.select.SelectOption>} value
 * @return {!proto.mesop.components.select.SelectType} returns this
*/
proto.mesop.components.select.SelectType.prototype.setOptionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 16, value);
};


/**
 * @param {!proto.mesop.components.select.SelectOption=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.select.SelectOption}
 */
proto.mesop.components.select.SelectType.prototype.addOptions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 16, opt_value, proto.mesop.components.select.SelectOption, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearOptionsList = function() {
  return this.setOptionsList([]);
};


/**
 * optional string label = 17;
 * @return {string}
 */
proto.mesop.components.select.SelectType.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectType} returns this
 */
proto.mesop.components.select.SelectType.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectType.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.select.SelectOption.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.select.SelectOption.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.select.SelectOption} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.select.SelectOption.toObject = function(includeInstance, msg) {
  var f, obj = {
    label: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    value: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.select.SelectOption}
 */
proto.mesop.components.select.SelectOption.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.select.SelectOption;
  return proto.mesop.components.select.SelectOption.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.select.SelectOption} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.select.SelectOption}
 */
proto.mesop.components.select.SelectOption.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.select.SelectOption.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.select.SelectOption.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.select.SelectOption} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.select.SelectOption.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string label = 1;
 * @return {string}
 */
proto.mesop.components.select.SelectOption.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectOption} returns this
 */
proto.mesop.components.select.SelectOption.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectOption} returns this
 */
proto.mesop.components.select.SelectOption.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectOption.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string value = 2;
 * @return {string}
 */
proto.mesop.components.select.SelectOption.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.select.SelectOption} returns this
 */
proto.mesop.components.select.SelectOption.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.select.SelectOption} returns this
 */
proto.mesop.components.select.SelectOption.prototype.clearValue = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.select.SelectOption.prototype.hasValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.mesop.components.select);
