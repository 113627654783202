<mat-form-field [style]="getStyle()">
  <mat-label>{{config().getLabel()}}</mat-label>
  <mat-select
    [disabled]="config().getDisabled()"
    [disableRipple]="config().getDisableRipple()"
    [tabIndex]="config().getTabIndex()"
    [placeholder]="config().getPlaceholder()!"
    [value]="config().getValue()"
    (openedChange)="onSelectOpenedChangeEvent($event)"
    (selectionChange)="onSelectSelectionChangeEvent($event)"
    [multiple]="config().getMultiple()"
  >
    @for(option of config().getOptionsList(); track $index) {
    <mat-option [value]="option.getValue()">{{option.getLabel()}}</mat-option>
    }
  </mat-select>
</mat-form-field>
