<mesop-error-box *ngIf="error != null" [error]="error" />

<div class="status">
  <mat-progress-bar
    data-testid="connection-progress-bar"
    *ngIf="showChannelProgressIndicator()"
    mode="query"
  ></mat-progress-bar>
</div>
<mat-sidenav-container style="height: 100%">
  <ng-container *ngIf="rootComponent != null">
    <component-renderer [component]="rootComponent"></component-renderer>
  </ng-container>
</mat-sidenav-container>
