// source: mesop/components/input/input.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.components.input.InputType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.input.InputType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.input.InputType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.input.InputType.displayName = 'proto.mesop.components.input.InputType';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.input.InputType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.input.InputType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.input.InputType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.input.InputType.toObject = function(includeInstance, msg) {
  var f, obj = {
    disabled: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f,
    id: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f,
    placeholder: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    required: (f = jspb.Message.getBooleanField(msg, 5)) == null ? undefined : f,
    type: (f = jspb.Message.getField(msg, 6)) == null ? undefined : f,
    value: (f = jspb.Message.getField(msg, 8)) == null ? undefined : f,
    readonly: (f = jspb.Message.getBooleanField(msg, 9)) == null ? undefined : f,
    hideRequiredMarker: (f = jspb.Message.getBooleanField(msg, 10)) == null ? undefined : f,
    color: (f = jspb.Message.getField(msg, 11)) == null ? undefined : f,
    floatLabel: (f = jspb.Message.getField(msg, 12)) == null ? undefined : f,
    appearance: (f = jspb.Message.getField(msg, 13)) == null ? undefined : f,
    subscriptSizing: (f = jspb.Message.getField(msg, 14)) == null ? undefined : f,
    hintLabel: (f = jspb.Message.getField(msg, 15)) == null ? undefined : f,
    label: (f = jspb.Message.getField(msg, 16)) == null ? undefined : f,
    onInputHandlerId: (f = jspb.Message.getField(msg, 17)) == null ? undefined : f,
    onEnterHandlerId: (f = jspb.Message.getField(msg, 24)) == null ? undefined : f,
    onBlurHandlerId: (f = jspb.Message.getField(msg, 25)) == null ? undefined : f,
    rows: (f = jspb.Message.getField(msg, 18)) == null ? undefined : f,
    autosize: (f = jspb.Message.getBooleanField(msg, 20)) == null ? undefined : f,
    minRows: (f = jspb.Message.getField(msg, 21)) == null ? undefined : f,
    maxRows: (f = jspb.Message.getField(msg, 22)) == null ? undefined : f,
    isTextarea: (f = jspb.Message.getBooleanField(msg, 19)) == null ? undefined : f,
    isNativeTextarea: (f = jspb.Message.getBooleanField(msg, 23)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.input.InputType}
 */
proto.mesop.components.input.InputType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.input.InputType;
  return proto.mesop.components.input.InputType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.input.InputType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.input.InputType}
 */
proto.mesop.components.input.InputType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDisabled(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlaceholder(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadonly(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHideRequiredMarker(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setColor(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFloatLabel(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAppearance(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubscriptSizing(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setHintLabel(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnInputHandlerId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnEnterHandlerId(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnBlurHandlerId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRows(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutosize(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinRows(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxRows(value);
      break;
    case 19:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTextarea(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsNativeTextarea(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.input.InputType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.input.InputType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.input.InputType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.input.InputType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeBool(
      5,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeString(
      6,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeString(
      8,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeBool(
      9,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeBool(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 11));
  if (f != null) {
    writer.writeString(
      11,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeString(
      12,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 13));
  if (f != null) {
    writer.writeString(
      13,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 14));
  if (f != null) {
    writer.writeString(
      14,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 16));
  if (f != null) {
    writer.writeString(
      16,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 17));
  if (f != null) {
    writer.writeString(
      17,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 24));
  if (f != null) {
    writer.writeString(
      24,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 25));
  if (f != null) {
    writer.writeString(
      25,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 18));
  if (f != null) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeBool(
      20,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 21));
  if (f != null) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 22));
  if (f != null) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 19));
  if (f != null) {
    writer.writeBool(
      19,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 23));
  if (f != null) {
    writer.writeBool(
      23,
      f
    );
  }
};


/**
 * optional bool disabled = 1;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getDisabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setDisabled = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearDisabled = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasDisabled = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string id = 2;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setId = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearId = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string placeholder = 3;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getPlaceholder = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setPlaceholder = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearPlaceholder = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasPlaceholder = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool required = 5;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setRequired = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearRequired = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasRequired = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string type = 6;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setType = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearType = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasType = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string value = 8;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setValue = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearValue = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasValue = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional bool readonly = 9;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getReadonly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setReadonly = function(value) {
  return jspb.Message.setField(this, 9, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearReadonly = function() {
  return jspb.Message.setField(this, 9, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasReadonly = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool hide_required_marker = 10;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getHideRequiredMarker = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setHideRequiredMarker = function(value) {
  return jspb.Message.setField(this, 10, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearHideRequiredMarker = function() {
  return jspb.Message.setField(this, 10, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasHideRequiredMarker = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string color = 11;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getColor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setColor = function(value) {
  return jspb.Message.setField(this, 11, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearColor = function() {
  return jspb.Message.setField(this, 11, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasColor = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string float_label = 12;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getFloatLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setFloatLabel = function(value) {
  return jspb.Message.setField(this, 12, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearFloatLabel = function() {
  return jspb.Message.setField(this, 12, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasFloatLabel = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string appearance = 13;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getAppearance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setAppearance = function(value) {
  return jspb.Message.setField(this, 13, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearAppearance = function() {
  return jspb.Message.setField(this, 13, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasAppearance = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string subscript_sizing = 14;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getSubscriptSizing = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setSubscriptSizing = function(value) {
  return jspb.Message.setField(this, 14, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearSubscriptSizing = function() {
  return jspb.Message.setField(this, 14, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasSubscriptSizing = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string hint_label = 15;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getHintLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setHintLabel = function(value) {
  return jspb.Message.setField(this, 15, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearHintLabel = function() {
  return jspb.Message.setField(this, 15, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasHintLabel = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string label = 16;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setLabel = function(value) {
  return jspb.Message.setField(this, 16, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearLabel = function() {
  return jspb.Message.setField(this, 16, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasLabel = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string on_input_handler_id = 17;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getOnInputHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setOnInputHandlerId = function(value) {
  return jspb.Message.setField(this, 17, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearOnInputHandlerId = function() {
  return jspb.Message.setField(this, 17, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasOnInputHandlerId = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string on_enter_handler_id = 24;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getOnEnterHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setOnEnterHandlerId = function(value) {
  return jspb.Message.setField(this, 24, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearOnEnterHandlerId = function() {
  return jspb.Message.setField(this, 24, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasOnEnterHandlerId = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string on_blur_handler_id = 25;
 * @return {string}
 */
proto.mesop.components.input.InputType.prototype.getOnBlurHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setOnBlurHandlerId = function(value) {
  return jspb.Message.setField(this, 25, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearOnBlurHandlerId = function() {
  return jspb.Message.setField(this, 25, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasOnBlurHandlerId = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * optional int32 rows = 18;
 * @return {number}
 */
proto.mesop.components.input.InputType.prototype.getRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setRows = function(value) {
  return jspb.Message.setField(this, 18, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearRows = function() {
  return jspb.Message.setField(this, 18, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasRows = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional bool autosize = 20;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getAutosize = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setAutosize = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearAutosize = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasAutosize = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional int32 min_rows = 21;
 * @return {number}
 */
proto.mesop.components.input.InputType.prototype.getMinRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setMinRows = function(value) {
  return jspb.Message.setField(this, 21, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearMinRows = function() {
  return jspb.Message.setField(this, 21, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasMinRows = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional int32 max_rows = 22;
 * @return {number}
 */
proto.mesop.components.input.InputType.prototype.getMaxRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setMaxRows = function(value) {
  return jspb.Message.setField(this, 22, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearMaxRows = function() {
  return jspb.Message.setField(this, 22, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasMaxRows = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional bool is_textarea = 19;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getIsTextarea = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 19, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setIsTextarea = function(value) {
  return jspb.Message.setField(this, 19, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearIsTextarea = function() {
  return jspb.Message.setField(this, 19, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasIsTextarea = function() {
  return jspb.Message.getField(this, 19) != null;
};


/**
 * optional bool is_native_textarea = 23;
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.getIsNativeTextarea = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.setIsNativeTextarea = function(value) {
  return jspb.Message.setField(this, 23, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.input.InputType} returns this
 */
proto.mesop.components.input.InputType.prototype.clearIsNativeTextarea = function() {
  return jspb.Message.setField(this, 23, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.input.InputType.prototype.hasIsNativeTextarea = function() {
  return jspb.Message.getField(this, 23) != null;
};


goog.object.extend(exports, proto.mesop.components.input);
