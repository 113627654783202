// source: mesop/components/table/table.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mesop.components.table.TableClickEvent', null, global);
goog.exportSymbol('proto.mesop.components.table.TableColumn', null, global);
goog.exportSymbol('proto.mesop.components.table.TableHeader', null, global);
goog.exportSymbol('proto.mesop.components.table.TableRow', null, global);
goog.exportSymbol('proto.mesop.components.table.TableType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.table.TableClickEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.table.TableClickEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.table.TableClickEvent.displayName = 'proto.mesop.components.table.TableClickEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.table.TableRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.table.TableRow.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.table.TableRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.table.TableRow.displayName = 'proto.mesop.components.table.TableRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.table.TableHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.table.TableHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.table.TableHeader.displayName = 'proto.mesop.components.table.TableHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.table.TableColumn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mesop.components.table.TableColumn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.table.TableColumn.displayName = 'proto.mesop.components.table.TableColumn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mesop.components.table.TableType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mesop.components.table.TableType.repeatedFields_, null);
};
goog.inherits(proto.mesop.components.table.TableType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mesop.components.table.TableType.displayName = 'proto.mesop.components.table.TableType';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.table.TableClickEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.table.TableClickEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.table.TableClickEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableClickEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowIndex: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    colIndex: (f = jspb.Message.getField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.table.TableClickEvent}
 */
proto.mesop.components.table.TableClickEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.table.TableClickEvent;
  return proto.mesop.components.table.TableClickEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.table.TableClickEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.table.TableClickEvent}
 */
proto.mesop.components.table.TableClickEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRowIndex(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColIndex(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.table.TableClickEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.table.TableClickEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.table.TableClickEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableClickEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 row_index = 1;
 * @return {number}
 */
proto.mesop.components.table.TableClickEvent.prototype.getRowIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.table.TableClickEvent} returns this
 */
proto.mesop.components.table.TableClickEvent.prototype.setRowIndex = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.table.TableClickEvent} returns this
 */
proto.mesop.components.table.TableClickEvent.prototype.clearRowIndex = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableClickEvent.prototype.hasRowIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 col_index = 2;
 * @return {number}
 */
proto.mesop.components.table.TableClickEvent.prototype.getColIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.table.TableClickEvent} returns this
 */
proto.mesop.components.table.TableClickEvent.prototype.setColIndex = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.table.TableClickEvent} returns this
 */
proto.mesop.components.table.TableClickEvent.prototype.clearColIndex = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableClickEvent.prototype.hasColIndex = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.table.TableRow.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.table.TableRow.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.table.TableRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.table.TableRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    index: (f = jspb.Message.getField(msg, 1)) == null ? undefined : f,
    cellValuesList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.table.TableRow}
 */
proto.mesop.components.table.TableRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.table.TableRow;
  return proto.mesop.components.table.TableRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.table.TableRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.table.TableRow}
 */
proto.mesop.components.table.TableRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIndex(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCellValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.table.TableRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.table.TableRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.table.TableRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCellValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional int32 index = 1;
 * @return {number}
 */
proto.mesop.components.table.TableRow.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mesop.components.table.TableRow} returns this
 */
proto.mesop.components.table.TableRow.prototype.setIndex = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.table.TableRow} returns this
 */
proto.mesop.components.table.TableRow.prototype.clearIndex = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableRow.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string cell_values = 2;
 * @return {!Array<string>}
 */
proto.mesop.components.table.TableRow.prototype.getCellValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.components.table.TableRow} returns this
 */
proto.mesop.components.table.TableRow.prototype.setCellValuesList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.table.TableRow} returns this
 */
proto.mesop.components.table.TableRow.prototype.addCellValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.table.TableRow} returns this
 */
proto.mesop.components.table.TableRow.prototype.clearCellValuesList = function() {
  return this.setCellValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.table.TableHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.table.TableHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.table.TableHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    sticky: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.table.TableHeader}
 */
proto.mesop.components.table.TableHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.table.TableHeader;
  return proto.mesop.components.table.TableHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.table.TableHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.table.TableHeader}
 */
proto.mesop.components.table.TableHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSticky(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.table.TableHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.table.TableHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.table.TableHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool sticky = 1;
 * @return {boolean}
 */
proto.mesop.components.table.TableHeader.prototype.getSticky = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.table.TableHeader} returns this
 */
proto.mesop.components.table.TableHeader.prototype.setSticky = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.table.TableHeader} returns this
 */
proto.mesop.components.table.TableHeader.prototype.clearSticky = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableHeader.prototype.hasSticky = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.table.TableColumn.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.table.TableColumn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.table.TableColumn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableColumn.toObject = function(includeInstance, msg) {
  var f, obj = {
    sticky: (f = jspb.Message.getBooleanField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.table.TableColumn}
 */
proto.mesop.components.table.TableColumn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.table.TableColumn;
  return proto.mesop.components.table.TableColumn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.table.TableColumn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.table.TableColumn}
 */
proto.mesop.components.table.TableColumn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSticky(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.table.TableColumn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.table.TableColumn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.table.TableColumn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableColumn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {boolean} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool sticky = 1;
 * @return {boolean}
 */
proto.mesop.components.table.TableColumn.prototype.getSticky = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mesop.components.table.TableColumn} returns this
 */
proto.mesop.components.table.TableColumn.prototype.setSticky = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.table.TableColumn} returns this
 */
proto.mesop.components.table.TableColumn.prototype.clearSticky = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableColumn.prototype.hasSticky = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mesop.components.table.TableType.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mesop.components.table.TableType.prototype.toObject = function(opt_includeInstance) {
  return proto.mesop.components.table.TableType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mesop.components.table.TableType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableType.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayedColumnsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    dataSourceList: jspb.Message.toObjectList(msg.getDataSourceList(),
    proto.mesop.components.table.TableRow.toObject, includeInstance),
    onTableClickEventHandlerId: (f = jspb.Message.getField(msg, 3)) == null ? undefined : f,
    header: (f = msg.getHeader()) && proto.mesop.components.table.TableHeader.toObject(includeInstance, f),
    columnsMap: (f = msg.getColumnsMap()) ? f.toObject(includeInstance, proto.mesop.components.table.TableColumn.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mesop.components.table.TableType}
 */
proto.mesop.components.table.TableType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mesop.components.table.TableType;
  return proto.mesop.components.table.TableType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mesop.components.table.TableType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mesop.components.table.TableType}
 */
proto.mesop.components.table.TableType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addDisplayedColumns(value);
      break;
    case 2:
      var value = new proto.mesop.components.table.TableRow;
      reader.readMessage(value,proto.mesop.components.table.TableRow.deserializeBinaryFromReader);
      msg.addDataSource(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOnTableClickEventHandlerId(value);
      break;
    case 4:
      var value = new proto.mesop.components.table.TableHeader;
      reader.readMessage(value,proto.mesop.components.table.TableHeader.deserializeBinaryFromReader);
      msg.setHeader(value);
      break;
    case 5:
      var value = msg.getColumnsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.mesop.components.table.TableColumn.deserializeBinaryFromReader, "", new proto.mesop.components.table.TableColumn());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mesop.components.table.TableType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mesop.components.table.TableType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mesop.components.table.TableType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mesop.components.table.TableType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayedColumnsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDataSourceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mesop.components.table.TableRow.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHeader();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mesop.components.table.TableHeader.serializeBinaryToWriter
    );
  }
  f = message.getColumnsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.mesop.components.table.TableColumn.serializeBinaryToWriter);
  }
};


/**
 * repeated string displayed_columns = 1;
 * @return {!Array<string>}
 */
proto.mesop.components.table.TableType.prototype.getDisplayedColumnsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.setDisplayedColumnsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.addDisplayedColumns = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.clearDisplayedColumnsList = function() {
  return this.setDisplayedColumnsList([]);
};


/**
 * repeated TableRow data_source = 2;
 * @return {!Array<!proto.mesop.components.table.TableRow>}
 */
proto.mesop.components.table.TableType.prototype.getDataSourceList = function() {
  return /** @type{!Array<!proto.mesop.components.table.TableRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mesop.components.table.TableRow, 2));
};


/**
 * @param {!Array<!proto.mesop.components.table.TableRow>} value
 * @return {!proto.mesop.components.table.TableType} returns this
*/
proto.mesop.components.table.TableType.prototype.setDataSourceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mesop.components.table.TableRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mesop.components.table.TableRow}
 */
proto.mesop.components.table.TableType.prototype.addDataSource = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mesop.components.table.TableRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.clearDataSourceList = function() {
  return this.setDataSourceList([]);
};


/**
 * optional string on_table_click_event_handler_id = 3;
 * @return {string}
 */
proto.mesop.components.table.TableType.prototype.getOnTableClickEventHandlerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.setOnTableClickEventHandlerId = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.clearOnTableClickEventHandlerId = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableType.prototype.hasOnTableClickEventHandlerId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional TableHeader header = 4;
 * @return {?proto.mesop.components.table.TableHeader}
 */
proto.mesop.components.table.TableType.prototype.getHeader = function() {
  return /** @type{?proto.mesop.components.table.TableHeader} */ (
    jspb.Message.getWrapperField(this, proto.mesop.components.table.TableHeader, 4));
};


/**
 * @param {?proto.mesop.components.table.TableHeader|undefined} value
 * @return {!proto.mesop.components.table.TableType} returns this
*/
proto.mesop.components.table.TableType.prototype.setHeader = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.clearHeader = function() {
  return this.setHeader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mesop.components.table.TableType.prototype.hasHeader = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * map<string, TableColumn> columns = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.mesop.components.table.TableColumn>}
 */
proto.mesop.components.table.TableType.prototype.getColumnsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.mesop.components.table.TableColumn>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.mesop.components.table.TableColumn));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.mesop.components.table.TableType} returns this
 */
proto.mesop.components.table.TableType.prototype.clearColumnsMap = function() {
  this.getColumnsMap().clear();
  return this;
};


goog.object.extend(exports, proto.mesop.components.table);
